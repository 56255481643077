export const routingNumberValidator = (number: number | string) => {
  if (!number) return false

  const routingNumber = number.toString()

  if (!routingNumber) return false

  /// In MICR form, the routing number is in the form of XXXXYYYYC
  /// Where XXXX is Federal Reserve Routing Symbol, YYYY is the Financial Institution Identifier, and C is the Check Digit.
  if (routingNumber.length !== 9) return false

  const regex = /^\d+$/
  if (!routingNumber.match(regex)) return false

  /// Within the 4 digit Federal Reserve Routing Symbol, the first two digits can only be 00 - 12, 21 - 32, 61 - 72, or 80
  const firstTwo = +routingNumber.substring(0, 2)
  const firstTwoValid =
    (firstTwo > 0 && firstTwo <= 12) ||
    (firstTwo >= 21 && firstTwo <= 32) ||
    (firstTwo >= 61 && firstTwo <= 72) ||
    firstTwo === 80
  if (!firstTwoValid) return false

  /// The check digit is the ninth digit of the routing number and must meet the following condition. This condition is mainly intended to reduce misrouting errors typically due to input errors.
  /// 3(d1+d4+d7) + 7(d2+d5+d8) + (d3+d6+d9) mod 10 = 0
  const sum =
    3 * (+routingNumber[0] + +routingNumber[3] + +routingNumber[6]) +
    7 * (+routingNumber[1] + +routingNumber[4] + +routingNumber[7]) +
    (+routingNumber[2] + +routingNumber[5] + +routingNumber[8])

  if (sum % 10 !== 0) return false

  return true
}
